import React, { useState } from "react"
import styled from "styled-components"
import queryString from 'query-string'

import SEO from "../../components/seo"
import Page from "../../components/layout/Page"
import PhotoGrid from "../../components/PhotoGrid"
import PageHeader from "../../components/layout/PageHeader"

import { colors, devices, photoCategories } from "../../settings"

const Keywords = styled.ul`
  list-style-type: none;
  margin: 20px 0px;
  padding: 0px;
  display: flex;
  flex-flow: wrap;

  @media ${devices.mobile} {
    margin: 10px 0px;
  }

  li {
    padding: 10px 15px;
    background-color: #e0e0e0;
    margin: 0px 5px 5px 0px;
    border-radius: 4px;
    cursor: pointer;

    @media ${devices.mobile} {
      padding: 5px 10px;
      margin: 0px 3px 3px 0px;
    }

    &.active {
      background-color: ${colors.blue} !important;
      color: ${colors.blueText};
    }
    &:hover {
      transform: scale(1.025);
      background-color: #ccc;
    }
  }
`

const WhyPhotosPage = () => {
  const urlParams = queryString.parse(typeof window !== `undefined` ? window.location.search : "");

  const [category, setCategory] = useState(urlParams.category || "");

  return (
    <>
      <SEO page="photos" title="Pictures of SignMonkey Products" />
      <Page breadcrumb={[
        { url: "/", title: "Home"},
        { url: "/why/", title: "Why SignMonkey"},
        { url: null, title: "Photos" }
      ]}>
          <PageHeader 
            title="Photos" 
            subtitle="Some examples of the awesome products we build here in the USA"
          />

          <Keywords>
            <li onClick={() => setCategory("")} className={category == "" ? "active":""}>
              All Signs
            </li>

            {photoCategories.map(cat => (
              <li 
                key={cat} 
                onClick={() => {
                  setCategory(cat);

                  const url = new URL(window.location);
                  url.searchParams.set('category', cat);
                  window.history.pushState(null, '', url.toString());                  
                }} 
                className={cat == category ? "active":""}
              >
                {cat}
              </li>
            ))}
          </Keywords>

          <PhotoGrid 
            keyword={""}
            category={category}
            social={true}
          />
      </Page>
    </>
  )
}

export default WhyPhotosPage
